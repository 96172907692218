import Teachers from '@/api/resources/teachers';

const state = {
  teacher: null,
  emails: null,
  help: null,
  recoverSent: false,
  recoveryTokenStatus: false,
};

const getters = {
  currentTeacher: () => (state.teacher),
  getEmails: () => (state.emails),
  getHelp: () => (state.help),
  getRecoverSent: () => (state.recoverSent),
  getRecoveryToeknStatus: () => (state.recoveryTokenStatus),
};

const actions = {
  login({ commit, dispatch }, payload) {
    dispatch('initLoading');
    Teachers.login(payload).then((response) => {
      dispatch('createSession', response);
      commit('setTeacher', response);
      dispatch('removeLoading');
    });
  },
  register({ commit, dispatch }, payload) {
    dispatch('initLoading');
    Teachers.validate(payload).then((response) => {
      dispatch('createSession', response);
      commit('setTeacher', response);
    });
  },
  update({ commit, dispatch }, payload) {
    dispatch('initLoading');
    Teachers.update(payload).then((response) => {
      dispatch('updateToken', response.token);
      commit('setTeacher', response);
      dispatch('removeLoading');
    });
  },
  logout({ commit, dispatch }) {
    commit('setTeacher', null);
    dispatch('destroySession');
  },
  fetchTeacher({ commit }) {
    Teachers.show().then((response) => {
      commit('setTeacher', response);
    });
  },
  findTeacher({ commit }, payload) {
    Teachers.find(payload).then((response) => {
      commit('setEmails', response);
    });
  },
  registerHelp({ commit }, payload) {
    Teachers.help(payload).then((response) => {
      commit('setHelp', response);
    });
  },
  recoverPassword({ commit }, payload) {
    Teachers.recoverPassword(payload).then(() => {
      commit('toggleRecovererSent');
    });
  },
  changeRecoverSent({ commit }) {
    commit('toggleRecovererSent');
  },
  validateRecoveryToken({ commit }, payload) {
    Teachers.verifyRecoveryToken(payload).then((response) => {
      commit('setRecoveryTokenStatus', response);
    });
  },
  newPassword({ commit }, payload) {
    Teachers.newPassword(payload).then(() => {
      commit('toggleRecovererSent');
    });
  },
};

const mutations = {
  setTeacher($state, payload) {
    const stateCopy = $state;
    stateCopy.teacher = payload;
  },
  setEmails($state, payload) {
    const stateCopy = $state;
    stateCopy.emails = payload;
  },
  setHelp($state, payload) {
    const stateCopy = $state;
    stateCopy.help = payload;
  },
  toggleRecovererSent($state) {
    const stateCopy = $state;
    stateCopy.recoverSent = !stateCopy.recoverSent;
  },
  setRecoveryTokenStatus($state, payload) {
    const stateCopy = $state;
    stateCopy.recoveryTokenStatus = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
