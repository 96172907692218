import Alert from '@/api/resources/alerts';

const state = {
  indisciplinAndHeatlhAlerts: null,
  socioemotionalAlerts: null,
  averageSocioemotionalOccurrences: null,
};

const getters = {
  getIndisciplinAndHeatlhAlerts: () => (state.indisciplinAndHeatlhAlerts),
  getSocioemotionalAlerts: () => (state.socioemotionalAlerts),
  getAverageSocioemotionalOccurrences: () => (state.averageSocioemotionalOccurrences),
};

const actions = {
  findIndisciplineAndHealthAlerts({ commit, dispatch }, payload) {
    Alert.alertsIndisciplineAndHeatlh(payload).then((response) => {
      commit('setIndisciplinAndHeatlhAlerts', response);
      dispatch('removeLoading');
    });
  },

  findSocioemotionalAlerts({ commit, dispatch }, payload) {
    Alert.alertsSocioemotional(payload).then((response) => {
      commit('setSocioemotionalAlerts', response);
      dispatch('removeLoading');
    });
  },

  findAverageSocioemotionalOccurrences({ commit, dispatch }, payload) {
    Alert.socioemotionalAverageByCategory(payload).then((response) => {
      commit('setAverageSocioemotional', response);
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setIndisciplinAndHeatlhAlerts($state, payload) {
    const stateCopy = $state;
    stateCopy.indisciplinAndHeatlhAlerts = payload;
  },
  setSocioemotionalAlerts($state, payload) {
    const stateCopy = $state;
    stateCopy.socioemotionalAlerts = payload;
  },
  setAverageSocioemotional($state, payload) {
    const stateCopy = $state;
    stateCopy.averageSocioemotionalOccurrences = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
