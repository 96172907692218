import PresenceList from '@/api/resources/presence_lists';

const state = {
  presenceList: [],
  presenceListDays: [],
};

const getters = {
  getPresenceList: () => (state.presenceList),
  getPresenceListDays: () => (state.presenceListDays),
};

const actions = {
  findPresenceListDays({ commit, dispatch }, payload) {
    dispatch('initLoading');
    PresenceList.index(payload).then((response) => {
      commit('setPresenceListDays', response);
      dispatch('removeLoading');
    });
  },
  findPresenceList({ commit, dispatch }, payload) {
    dispatch('initLoading');
    PresenceList.show(payload).then((response) => {
      commit('setPresenceList', response);
      dispatch('removeLoading');
    });
  },
  sendPresentStudents({ commit, dispatch }, payload) {
    dispatch('initLoading');
    PresenceList.update(payload).then((response) => {
      commit('setMessage', response);
      dispatch('removeLoading');
      dispatch('findPresenceListDays', { school_class_id: payload.school_class_id });
    });
  },
};

const mutations = {
  setPresenceListDays($state, payload) {
    const stateCopy = $state;
    stateCopy.presenceListDays = payload;
  },
  setPresenceList($state, payload) {
    const stateCopy = $state;
    stateCopy.presenceList = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
