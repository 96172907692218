<template>
  <v-snackbar
    :timeout="5000"
    rounded="0"
    :value="hasMessage"
    color="primary"
    @input="closeSnackbar"
  >
    {{ messageText }}
    <v-btn
      v-snackbar
      color="white"
      text
      @click="closeSnackbar"
    >
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Snackbar',
  computed: {
    ...mapGetters(['messages', 'hasMessage']),
    messageText() {
      if (this.messages instanceof Array) {
        return this.messages.join(', ');
      }
      return this.messages;
    },
  },

  methods: {
    ...mapActions(['removeMessage']),
    closeSnackbar() {
      this.removeMessage();
    },
  },
};
</script>
