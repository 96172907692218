import Vue from 'vue';

Vue.mixin({
  methods: {
    unmask(text) {
      const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/;
      return text ? String(text).replace(new RegExp(defaultDelimiters, 'g'), '') : text;
    },
  },
});
