import CpfValidate from '@/utils/validators/rules/cpf';

const validator = {
  message() {
    return 'CPF inválido';
  },

  validate(value) {
    return CpfValidate(value);
  },
};

export default validator;
