import Vue from 'vue';
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate';
import ptBr from 'vee-validate/dist/locale/pt_BR.json';
import {
  required,
  email,
  min,
  confirmed,
} from 'vee-validate/dist/rules';
import CpfValidator from '@/utils/validators/cpf.validator';

// navigator.language
localize('ptBr', ptBr);
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'as senhas não batem',
});
extend('cpf', CpfValidator);
extend('required', required);
extend('email', email);
extend('min', min);
extend('confirmed', confirmed);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
