const state = {
  tab: null,
};

const getters = {
  currentTab: () => (state.tab),
};

const actions = {
  changeTab({ commit }, data) {
    commit('setCurrentTab', data);
  },
};

const mutations = {
  setCurrentTab($state, payload) {
    const stateCopy = $state;
    stateCopy.tab = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
