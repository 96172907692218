const state = {
  toolbarText: null,
  options: {
    spacer: {
      has: false,
    },
    moreOptions: {
      has: false,
      actions: [],
    },
  },
};

const getters = {
  getToolbarText: () => (state.toolbarText),
  getToolbarNavOptions: (state) => (state.options),
};

const actions = {
  changeToolbarText({ commit }, payload) {
    commit('storeToolbarText', payload);
  },
  changeToolbarNav({ commit }, { options }) {
    commit('setToolbarNav', options);
  },
};

const mutations = {
  storeToolbarText($state, payload) {
    const stateCopy = $state;
    stateCopy.toolbarText = payload;
  },
  setToolbarNav(state, payload) {
    state.options = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
