import Post from '@/api/resources/posts';

const state = {
  posts: [],
  post: null,
};

const getters = {
  getTeacherPosts: () => (state.posts),
  getPost: () => (state.post),
};

const actions = {
  findTeacherPosts({ commit, dispatch }, payload) {
    Post.index(payload).then((response) => {
      commit('setPosts', response.posts);
      if (response.pagy.next) {
        dispatch('nextPage');
        dispatch('loaded');
      } else {
        dispatch('complete');
      }
    });
  },
  findPost({ commit, dispatch }, params) {
    dispatch('initLoading');
    Post.show(params).then((response) => {
      commit('setPost', response);
      dispatch('removeLoading');
    });
  },
  clearPosts({ commit }) {
    commit('clearPosts');
  },
};

const mutations = {
  setPosts($state, payload) {
    const stateCopy = $state;
    stateCopy.posts = stateCopy.posts.concat(payload);
  },
  setPost($state, payload) {
    const stateCopy = $state;
    stateCopy.post = payload;
  },
  clearPosts($state) {
    const stateCopy = $state;
    stateCopy.posts = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
