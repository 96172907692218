import ApiService from '../api.service';

const Teachers = {
  login: (params) => ApiService.post('api/v2/teachers/sign_in', params),
  update: (params) => ApiService.put('api/v2/teachers/me', params),
  find: (params) => ApiService.query('api/v2/teachers/find_teacher_register', params),
  validate: (params) => ApiService.post('api/v2/teachers/validate_teacher_register', params),
  recoverPassword: (params) => ApiService.post('api/v2/teachers/recover_password', params),
  show: () => ApiService.get('api/v2/teachers/me'),
  help: (params) => ApiService.post('api/v2/teachers/register_aids', params),
  verifyRecoveryToken: (params) => ApiService.post('api/v1/valid_token', params),
  newPassword: (params) => ApiService.post('api/v1/new_password', params),
};

export default Teachers;
