<template>
  <div class="edudash-toolbar-nav">
    <v-app-bar
      v-if="hasToolbar"
      color="primary"
      dark
      app
      tabs
      flat
      :height="computedHeight"
    >
      <v-app-bar-nav-icon
        v-if="hasArrowBack"
        @click.stop="setAction"
      >
        <v-icon>
          {{ navIcon }}
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        class="edudash-toolbar-title"
      >
        <v-img
          v-if="!hasArrowBack"
          :src="require('@/assets/icons/logo_home.svg')"
          class="logo-home"
          width="130px"
        />
        <div
          :class="toolbarTitleClass"
        >
          {{ toolbarText }}
        </div>
      </v-toolbar-title>

      <v-spacer
        v-if="hasArrowBack"
      />

      <v-menu
        v-if="hasMoreOptions"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            class="more-options"
            v-on="on"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(action, index) in getToolbarNavOptions.moreOptions.actions"
            :key="index"
            @click="action.method"
          >
            <v-list-item-title>{{ action.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <template
        v-if="hasTabs"
        #extension
      >
        <v-tabs
          grow
        >
          <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            class="nav-tab"
            @click="goTo(tab.route)"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ToolbarNav',
  components: {
  },
  computed: {
    ...mapGetters([
      'getCity',
      'getToolbarText',
      'getToolbarNavOptions',
      'currentTeacher',
    ]),
    hasTabs() {
      return this.$route.meta.hasTabs || this.$route.params.hasTabs;
    },
    hasToolbar() {
      return this.$route.name !== 'login';
    },
    tabs() {
      if (this.hasTabs) {
        return this.$route.meta.tabs || this.$route.params.tabs;
      }
      return '';
    },
    navIcon() {
      if (this.$route.meta.icon) {
        return this.$route.meta.icon;
      }
      return 'arrow_back';
    },
    hasArrowBack() {
      return this.$route.meta.hasArrowBack;
    },
    hasMoreOptionsAndLogo() {
      return (!this.hasArrowBack && this.$route.meta.hasMoreOptions);
    },
    hasMoreOptions() {
      return this.$route.meta.hasMoreOptions;
    },
    computedHeight() {
      if (this.hasArrowBack) {
        return '74px';
      }
      return '96px';
    },
    toolbarText() {
      if (this.$route.meta.hasToolbarTextGetter) {
        return this.getToolbarText;
      }
      if (this.hasArrowBack) {
        return this.$route.meta.toolbarText;
      }
      if (this.currentTeacher && this.currentTeacher.city != null) {
        return this.currentTeacher.city;
      }
      return '';
    },
    toolbarTitleClass() {
      if (this.hasArrowBack) {
        return 'toolbar-title-with-arrow';
      }
      return 'toolbar-title';
    },
  },
  methods: {
    setAction() {
      if (this.navIcon === 'arrow_back') {
        this.$router.push({
          name: this.$route.meta.backPath || this.$route.params.backPath,
          params: this.$router.params,
        });
      }
    },
    goTo(item) {
      if (this.$route.name !== item) {
        this.$router.push({
          name: item,
          params: this.$route.meta,
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .edudash-toolbar-nav {
    .v-toolbar__content {
      justify-content: center;
    }

    .more-options {
      position: absolute;
      width: fit-content;
      right: 18px;
      top: 12px;
      background: $color-primary;
    }

    .logo-home {
      margin-top: 24px;
    }

    .edudash-toolbar-title {
      @include font-open-sans-bold;
      font-size: $font-size-extra-small;

      .toolbar-title {
        text-align: center;
        margin-top: 8px;
        padding-bottom: 16px;
      }

      .toolbar-title-with-arrow {
        font-size: $font-size-base;
        padding-top: 0;
      }
    }
    .nav-tab.v-tab {
      @include font-monteserrat-semi-bold;
      font-size: $font-size-extra-small;
      letter-spacing: initial;
      text-transform: initial;
    }
  }
</style>
