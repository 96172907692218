import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
    values: {
      custom_logo: {
        component: () => import('@/components/icons/Logo.vue'),
      },
      custom_alert: {
        component: () => import('@/components/icons/Alert.vue'),
      },
      custom_group: {
        component: () => import('@/components/icons/Group.vue'),
      },
      custom_notes: {
        component: () => import('@/components/icons/Notes.vue'),
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#1144d7',
        secondary: '#B2B0C1',
        text_color: '#fff',
        secondary_label: '#FFFFFF',
        primary_button: '#29C68F',
        success: '#29C68F',
        text_secondary_color: '#222222',
        bg_color: '#EEF5F1',
      },
    },
  },
  options: {
    customProperties: true,
  },
});
