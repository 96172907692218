import ClassDiary from '@/api/resources/class_diaries';

const state = {
  ClassDiary: [],
  ClassDiaryDays: [],
  pdfToken: '',
};

const getters = {
  getClassDiary: () => (state.ClassDiary),
  getClassDiaryDays: () => (state.ClassDiaryDays),
  getPdfToken: () => (state.pdfToken),
};

const actions = {
  findClassDiaryDays({ commit, dispatch }, payload) {
    dispatch('initLoading');
    ClassDiary.index(payload).then((response) => {
      commit('setClassDiaryDays', response);
      dispatch('removeLoading');
    });
  },
  findClassDiary({ commit, dispatch }, payload) {
    dispatch('initLoading');
    ClassDiary.show(payload)
      .then((response) => {
        commit('setClassDiary', response);
        commit('setSchoolYearIsValid', true);
      })
      .catch(() => commit('setSchoolYearIsValid', false))
      .finaly(dispatch('removeLoading'));
  },
  sendClassDiary({ commit, dispatch }, payload) {
    dispatch('initLoading');
    ClassDiary.update(payload).then((response) => {
      commit('setMessage', response);
      dispatch('removeLoading');
      dispatch('findClassDiaryDays', { school_class_id: payload.school_class_id });
    });
  },
  generatePdfToken({ commit }, payload) {
    ClassDiary.encriptClassDiary(payload).then((response) => {
      commit('setPdfToken', response);
    });
  },
};

const mutations = {
  setClassDiaryDays($state, payload) {
    const stateCopy = $state;
    stateCopy.ClassDiaryDays = payload;
  },
  setClassDiary($state, payload) {
    const stateCopy = $state;
    stateCopy.ClassDiary = payload;
  },
  setPdfToken($state, payload) {
    const stateCopy = $state;
    stateCopy.pdfToken = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
