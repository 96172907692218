const state = {
  page: 1,
  itemsPerPage: 10,
  pagination: null,
};

const getters = {
  currentPage: () => (state.page),
  itemsPerPage: () => (state.itemsPerPage),
};

const actions = {
  initPagination({ commit }, data) {
    commit('setPaginationState', data);
  },
  nextPage({ commit }) {
    commit('incrementPage');
  },
  complete({ commit }) {
    commit('setCompleted');
  },
  loaded({ commit }) {
    commit('setLoaded');
  },
  restartPagination({ commit }) {
    commit('resetPage');
  },
};

const mutations = {
  setPaginationState($state, payload) {
    const stateCopy = $state;
    stateCopy.pagination = payload;
  },
  incrementPage($state) {
    const stateCopy = $state;
    stateCopy.page += 1;
  },
  setCompleted($state) {
    const stateCopy = $state;
    stateCopy.pagination.complete();
  },
  setLoaded($state) {
    const stateCopy = $state;
    stateCopy.pagination.loaded();
  },
  resetPage($state) {
    const stateCopy = $state;
    stateCopy.page = 1;
    if (stateCopy.pagination) {
      stateCopy.pagination.reset();
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
