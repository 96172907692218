import School from '@/api/resources/schools';

const state = {
  schools: null,
  school: {},
  schoolYearIsValid: false,
};

const getters = {
  getTeacherSchools: () => (state.schools),
  getSchool: () => (state.school),
  getSchoolYearIsValid: () => (state.schoolYearIsValid),
};

const actions = {
  findTeacherSchools({ commit, dispatch }, payload) {
    dispatch('initLoading');
    School.index(payload).then((response) => {
      commit('setSchools', response);
      dispatch('removeLoading');
    });
  },
  storeSchool({ commit }, payload) {
    commit('setSchool', payload);
  },
};

const mutations = {
  setSchools($state, payload) {
    const stateCopy = $state;
    stateCopy.schools = payload;
  },
  setSchool($state, payload) {
    const stateCopy = $state;
    stateCopy.school = payload;
  },
  setSchoolYearIsValid($state, payload) {
    const stateCopy = $state;
    stateCopy.schoolYearIsValid = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
