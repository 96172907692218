import Vue from 'vue';
import Vuex from 'vuex';

import Loading from './modules/loading';
import Session from './modules/session';
import Snackbar from './modules/snackbar';
import Toolbar from './modules/toolbar';
import Error from './modules/error';
import School from './modules/school';
import SchoolClass from './modules/school_class';
import PresenceList from './modules/presence_list';
import Category from './modules/category';
import StudentOccurrence from './modules/student_occurrence';
import ClassDiary from './modules/class_diary';
import Alerts from './modules/alerts';
import Teacher from './modules/teacher';
import Post from './modules/post';
import Pagination from './modules/pagination';
import Report from './modules/report';
import BottomNav from './modules/bottom_nav';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Loading,
    Session,
    Snackbar,
    Toolbar,
    Error,
    School,
    SchoolClass,
    PresenceList,
    Category,
    StudentOccurrence,
    ClassDiary,
    Alerts,
    Teacher,
    Post,
    Pagination,
    Report,
    BottomNav,
  },
});
