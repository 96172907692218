import Vue from 'vue';

Vue.mixin({
  methods: {
    isEmpty(object) {
      return Object.keys(object).length === 0;
    },
    arrayIsEmpty(array) {
      // necessary because there is possibillity of an array with all elements with null value
      return array.every((element) => element === null || element === 0);
    },
  },
});
