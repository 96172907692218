<template>
  <div class="bottom-nav-edudash">
    <v-bottom-navigation
      v-model="bottomNav"
      app
      background-color="secondary_label"
      color="primary"
      height="64px"
    >
      <v-btn
        value="feedList"
        text
      >
        <span>Home</span>
        <v-icon>$vuetify.icons.values.custom_logo</v-icon>
      </v-btn>

      <v-btn
        value="schoolClassPresenceList"
      >
        <span>{{ $t('views.bottom_nav.presence_list') }}</span>
        <v-icon>$vuetify.icons.values.custom_group</v-icon>
      </v-btn>

      <v-btn
        value="alertsIndisciplineAndHealth"
      >
        <span>{{ $t('views.bottom_nav.alerts') }}</span>
        <v-icon>$vuetify.icons.values.custom_alert</v-icon>
      </v-btn>

      <v-btn
        value="classDiary"
      >
        <span>{{ $t('views.bottom_nav.class_diary') }}</span>
        <v-icon>$vuetify.icons.values.custom_notes</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BottomNav',
  data() {
    return {
      bottomNav: this.bottomNavValue,
    };
  },
  computed: {
    ...mapGetters(['currentTeacher', 'currentTab']),
    bottomNavValue() {
      return this.currentTab;
    },
  },
  watch: {
    bottomNav(page) {
      if (this.$route.name !== page) {
        this.$router.push({ name: page, params: { jwt_token: this.currentTeacher.jwt_token } });
      }
    },
    currentTab(value) {
      if (value) {
        this.bottomNav = value;
      }
    },
  },
  mounted() {
    this.bottomNav = this.$route.name;
  },
};
</script>

<style lang="scss">
  .bottom-nav-edudash {
    @include font-monteserrat-bold;

    .v-btn__content {
      font-size: $font-size-extra-super-small;

      span {
        margin-top: 4px;
      }
    }

    .v-item-group.v-bottom-navigation .v-btn.v-btn--active {
      border-top: 2px solid $color-primary;
    }

    .theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
      color: $color-bottom-bar !important; // necessary to override element !important :(
    }
  }
</style>
