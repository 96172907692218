import Vue from 'vue';
import { formatDistanceToNow, format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

Vue.mixin({

  methods: {
    formatDate(date) {
      const parsedDate = parseISO(date);
      const relativeHour = formatDistanceToNow(parsedDate, { locale: pt, includeSeconds: true });
      const formattedDate = format(parsedDate, 'dd/MM/yyyy');

      return `${relativeHour} - ${formattedDate}`;
    },
  },
});
