import ApiService from '../api.service';

const PresenceList = {
  index: (params) => ApiService.query('/api/v2/teachers/me/class_diaries/', params),
  show: (params) => ApiService.query(`/api/v2/teachers/me/class_diaries/${params.day}`, params),
  update: (params) => ApiService.put(`/api/v2/teachers/me/class_diaries/${params.day}`, params),
  encriptClassDiary: (params) => ApiService.query('/api/v2/teachers/me/class_diaries/encript_class_diary', params),
};

export default PresenceList;
