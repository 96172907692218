import ApiService from '../api.service';

const PresenceList = {
  index: (params) => ApiService.query('/api/v2/teachers/me/presence_lists/', params),
  show: (params) => ApiService.query(`/api/v2/teachers/me/presence_lists/${params.day}`, params),
  update: (params) => ApiService.put(`/api/v2/teachers/me/presence_lists/${params.day}`, params),
  status: (params) => ApiService.query(`/api/v2/teachers/me/presence_lists/${params.day}/status`, params),
};

export default PresenceList;
