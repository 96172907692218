<template>
  <v-app>
    <toolbar-nav v-if="needToolbar" />
    <loading />
    <v-content id="main-app">
      <v-container
        class="pa-0"
        fluid
      >
        <router-view />
      </v-container>
    </v-content>
    <snackbar />
    <bottom-nav v-if="needBottomNav" />

    <!-- <v-footer app></v-footer> -->
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ToolbarNav from './components/navs/ToolbarNav.vue';
import Loading from './components/feedbacks/Loading.vue';
import Snackbar from './components/feedbacks/Snackbar.vue';
import BottomNav from './components/navs/BottomNav.vue';

export default {
  name: 'App',
  components: {
    ToolbarNav,
    Loading,
    Snackbar,
    BottomNav,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters([
      'hasSession',
      'currentTeacher',
      'hasMessage',
      'hasSession',
    ]),
    needToolbar() {
      return (this.$route.meta.needToolbar || this.hasSession);
    },
    needBottomNav() {
      return this.$route.meta.needBottomNav || false;
    },
  },
  watch: {
    currentTeacher(currentTeacher) {
      if (!currentTeacher && this.$route.meta.requiresAuth) {
        this.removeLoading();
        this.$router.push('/entrar');
      } else if (currentTeacher && !this.$route.meta.requiresAuth) {
        this.$router.push({
          name: 'feedList', params: { jwt_token: this.currentTeacher.jwt_token },
        });
      }
    },
  },
  mounted() {
    if (!this.currentTeacher && this.hasSession) {
      this.initLoading();
      this.fetchTeacher();
    }
  },
  methods: {
    ...mapActions([
      'fetchTeacher',
      'initLoading',
      'removeLoading',
    ]),
  },
};
</script>
