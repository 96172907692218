import SchoolClasses from '@/api/resources/school_classes';

const state = {
  schoolClass: null,
};

const getters = {
  getSchoolClass: () => (state.schoolClass),
};

const actions = {
  findSchoolClass({ commit, dispatch }, payload) {
    dispatch('initLoading');
    SchoolClasses.show(payload).then((response) => {
      commit('setSchoolClass', response);
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setSchoolClass($state, payload) {
    const stateCopy = $state;
    stateCopy.schoolClass = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
