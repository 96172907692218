import StudentOccurrence from '@/api/resources/student_occurrences';

const state = {
  occurrenceSuccess: false,
  studentOccurrences: [],
  student: null,
};

const getters = {
  getOccurrenceSuccess: () => (state.occurrenceSuccess),
  getStudentOccurrences: () => (state.studentOccurrences),
  getStudent: () => (state.student),
};

const actions = {
  findStudentOccurrences({ commit, dispatch }, payload) {
    dispatch('initLoading');
    StudentOccurrence.index(payload).then((response) => {
      commit('setStudentOccurrences', response);
      dispatch('removeLoading');
    });
  },

  saveStudentOccurrences({ commit }, payload) {
    commit('setStudentOccurrences', payload);
  },

  createSocioemotionalOccurrence({ commit, dispatch }, payload) {
    const { occurrence } = payload;

    payload.socioemotional.forEach((element) => {
      occurrence.socio_criteria.push(
        ...element.criteria.filter((criterium) => criterium.score > 0),
      );
    });
    dispatch('initLoading');
    StudentOccurrence.update(occurrence).then((response) => {
      commit('setOccurrenceSuccess', true);
      commit('setMessage', response);
      dispatch('removeLoading');
    });
  },

  createHealthAndIndisciplineOccurrence({ commit, dispatch }, payload) {
    const { occurrence } = payload;

    payload.healthIndiscipline.forEach((category) => {
      occurrence.criteria.push(
        ...category.criteria.map((criterium) => (
          { id: criterium.id, selected: criterium.selected }
        )),
      );
    });
    dispatch('initLoading');
    StudentOccurrence.update(occurrence).then((response) => {
      commit('setOccurrenceSuccess', true);
      commit('setMessage', response);
      dispatch('removeLoading');
    });
  },
  saveStudent({ commit }, payload) {
    commit('setStudent', payload);
  },
  changeOccurrenceSuccess({ commit }) {
    commit('setOccurrenceSuccess', false);
  },
};

const mutations = {
  setOccurrenceSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.occurrenceSuccess = payload;
  },
  setStudentOccurrences($state, payload) {
    const stateCopy = $state;
    stateCopy.studentOccurrences = payload;
  },
  setStudent($state, payload) {
    const stateCopy = $state;
    stateCopy.student = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
