import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = new Router({
  routes: [
    {
      path: '/',
      redirect: '/entrar',
    },
    {
      path: '/sair?jwt_token=:jwt_token',
      name: 'logout',
      component: () => import('@/views/Logout.vue'),
    },
    {
      path: '/chamada',
      name: 'schoolClassPresenceList',
      component: () => import(/* webpackChunkName: "presenceList" */ '@/views/schoolClass/List.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: true,
        needToolbar: true,
        hasArrowBack: false,
      },
    },
    {
      path: '/chamada/:schoolClassId',
      name: 'presenceList',
      component: () => import(/* webpackChunkName: "presenceList" */ '@/views/presence/List.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: false,
        needToolbar: true,
        hasArrowBack: true,
        hasTabs: true,
        hasToolbarTextGetter: true,
        backPath: 'schoolClassPresenceList',
        tabs: [
          {
            name: 'Chamada',
            route: 'presenceList',
          },
          {
            name: 'Histórico',
            route: 'presenceHistorical',
          },
        ],
      },
    },
    {
      path: '/chamada/:schoolClassId/ocorrencia/:studentId/:day',
      name: 'healthAndIndiscipline',
      component: () => import(/* webpackChunkName: "presenceList" */ '@/views/occurrences/HealthAndIndiscipline.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: false,
        needToolbar: true,
        hasArrowBack: true,
        hasTabs: true,
        hasToolbarTextGetter: false,
        toolbarText: 'Ocorrência',
        backPath: 'schoolClassPresenceList',
        tabs: [
          {
            name: 'Indisciplina e Saúde',
            route: 'healthAndIndiscipline',
          },
          {
            name: 'Socioemocional',
            route: 'socioemotional',
          },
        ],
      },
    },
    {
      path: '/chamada/:schoolClassId/ocorrencia/:studentId/:day/socioemotional',
      name: 'socioemotional',
      component: () => import(/* webpackChunkName: "presenceList" */ '@/views/occurrences/Socioemotional.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: false,
        needToolbar: true,
        hasArrowBack: true,
        hasTabs: true,
        hasToolbarTextGetter: false,
        toolbarText: 'Ocorrência',
        backPath: 'schoolClassPresenceList',
        tabs: [
          {
            name: 'Indisciplina e Saúde',
            route: 'healthAndIndiscipline',
          },
          {
            name: 'Socioemocional',
            route: 'socioemotional',
          },
        ],
      },
    },
    {
      path: '/chamada/:schoolClassId/historico',
      name: 'presenceHistorical',
      component: () => import(/* webpackChunkName: "presenceHistorical" */ '@/views/presence/Historical.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: false,
        needToolbar: true,
        hasArrowBack: true,
        hasToolbarTextGetter: true,
        backPath: 'schoolClassPresenceList',
      },
    },
    {
      path: '/ocorrencias/:studentId',
      name: 'historicalOccurrences',
      component: () => import(/* webpackChunkName: "presenceHistorical" */ '@/views/occurrences/Historical.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: false,
        needToolbar: true,
        hasArrowBack: true,
        hasToolbarTextGetter: true,
      },
    },
    {
      path: '/feed?jwt_token=:jwt_token',
      name: 'feedList',
      component: () => import(/* webpackChunkName: "feed" */ '@/views/feedList/FeedList.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: true,
        needToolbar: true,
        hasArrowBack: false,
        hasMoreOptions: true,
      },
    },
    {
      path: '/perfil',
      name: 'profile',
      component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Profile.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: false,
        needToolbar: true,
        hasArrowBack: true,
        hasToolbarTextGetter: true,
        backPath: 'feedList',
      },
    },
    {
      path: '/perfil/:id/editar',
      name: 'editProfile',
      component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Edit.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: false,
        needToolbar: true,
        hasArrowBack: true,
        hasToolbarTextGetter: true,
        backPath: 'profile',
      },
    },
    {
      path: '/post/:postId',
      name: 'postDetails',
      component: () => import(/* webpackChunkName: "postDetails" */ '@/views/posts/Details.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
        needBottomNav: false,
        hasArrowBack: true,
        backPath: 'feedList',
        hasToolbarTextGetter: true,
        hasMoreOptions: true,
      },
    },
    {
      path: '/alertas/indisciplina-e-saude',
      name: 'alertsIndisciplineAndHealth',
      component: () => import(/* webpackChunkName: "alertsIndisciplineAndHealth" */ '@/views/alerts/IndisciplineAndHealth.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: true,
        needToolbar: true,
        hasArrowBack: false,
        hasTabs: true,
        tabs: [
          {
            name: 'Indisciplina e saúde',
            route: 'alertsIndisciplineAndHealth',
          },
          {
            name: 'Socioemocional',
            route: 'alertsSocioemotional',
          },
        ],
      },
    },
    {
      path: '/alertas/socioemocionais',
      name: 'alertsSocioemotional',
      component: () => import(/* webpackChunkName: "alertsIndisciplineAndHealth" */ '@/views/alerts/Socioemotional.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: true,
        needToolbar: true,
        hasArrowBack: false,
        hasTabs: true,
        tabs: [
          {
            name: 'Indisciplina e saúde',
            route: 'alertsIndisciplineAndHealth',
          },
          {
            name: 'Socioemocional',
            route: 'alertsSocioemotional',
          },
        ],
      },
    },
    {
      path: '/diarios-de-classe',
      name: 'classDiary',
      component: () => import(/* webpackChunkName: "classDiary" */ '@/views/classDiary/ClassDiary.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: true,
        needToolbar: true,
        hasArrowBack: false,
      },
    },
    {
      path: '/diarios-de-classe/exportar',
      name: 'classDiaryExport',
      component: () => import(/* webpackChunkName: "classDiary" */ '@/views/classDiary/Export.vue'),
      meta: {
        requiresAuth: true,
        needBottomNav: false,
        needToolbar: true,
        hasArrowBack: true,
        hasToolbarTextGetter: true,
        backPath: 'classDiary',
      },
    },
    {
      path: '/cadastro',
      name: 'register',
      component: () => import(/* webpackChunkName: "register" */ '@/views/register/Register.vue'),
      meta: {
        needToolbar: true,
        icon: 'mdi-arrow-left',
        requiresAuth: false,
      },
      children: [
        {
          path: 'passo-1',
          name: 'registerStepOne',
          component: () => import(/* webpackChunkName: "register" */ '@/views/register/StepOne.vue'),
          meta: {
            needToolbar: true,
            requiresAuth: false,
            needBottomNav: false,
            hasArrowBack: true,
            hasToolbarTextGetter: true,
            backPath: 'login',
          },
        },
        {
          path: 'passo-2',
          name: 'registerStepTwo',
          component: () => import(/* webpackChunkName: "register" */ '@/views/register/StepTwo.vue'),
          meta: {
            needToolbar: true,
            requiresAuth: false,
            needBottomNav: false,
            hasArrowBack: true,
            hasToolbarTextGetter: true,
            backPath: 'registerStepOne',
          },
        },
        {
          path: 'ajuda',
          name: 'help',
          component: () => import(/* webpackChunckName: "register" */ '@/views/register/Help.vue'),
          meta: {
            needToolbar: true,
            requiresAuth: false,
            needBottomNav: false,
            hasArrowBack: true,
            hasToolbarTextGetter: true,
            backPath: 'registerStepOne',
          },
        },
      ],
    },
    {
      path: '/entrar',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    },
    {
      path: '/recuperar-senha',
      name: 'PasswordRecover',
      component: () => import(/* webpackChunkName: "login" */ '@/views/password/Recover.vue'),
      meta: {
        needToolbar: true,
        hasArrowBack: true,
        hasToolbarTextGetter: true,
        backPath: 'login',
      },
    },
    {
      path: '/recuperar-senha/:token/nova-senha/:email',
      name: 'NewPassword',
      component: () => import(/* webpackChunkName: "newPassword" */ '@/views/password/New.vue'),
      meta: {
        needToolbar: true,
        hasToolbarTextGetter: true,
        backPath: 'login',
      },
    },
    {
      path: '/diarios-de-classe/compartilhar?title=:title&text=:text&url=:url',
      name: 'share',
      component: () => import(/* webpackChunkName: "classDiary" */ '@/views/classDiary/Share.vue'),
    },
  ],
});

export default routes;
