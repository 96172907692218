import Report from '@/api/resources/reports';

const state = {
  report: null,
};

const getters = {
  getReport: () => (state.report),
};

const actions = {
  createReport({ commit }, params) {
    Report.create(params).then((response) => {
      commit('setReport', response);
    });
  },
};

const mutations = {
  setReport($state, payload) {
    const stateCopy = $state;
    stateCopy.report = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
