import Category from '@/api/resources/categories';

const state = {
  categories: [],
};

const getters = {
  getCategories: () => (state.categories),
};

const actions = {
  findCategories({ commit, dispatch }, payload) {
    dispatch('initLoading');
    Category.index(payload).then((response) => {
      commit('setCategories', response);
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setCategories($state, payload) {
    const stateCopy = $state;
    stateCopy.categories = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
